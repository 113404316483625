<template>
  <div class="row">
    <div class="col-md-12">
      <user-datatable/>
    </div>
  </div>
</template>

<script>
  import UserDatatable from "@/pages/Users/datatables/UserDatatable";
  import {mapMutations} from "vuex";
  import UserIndexHelper from "@/components/Helpers/UserIndexHelper";

  export default {
    name: "IndexPage",
    components: {UserDatatable},
    data() {
      return {
        helperComponent: UserIndexHelper
      }
    },
    mounted() {
      this.setHelper(this.helperComponent);
    },
    methods: {
      ...mapMutations({
        setHelper: 'common/setHelper',
      }),
    },
    beforeDestroy() {
      this.setHelper()
    }
  }
</script>

<style scoped>

</style>
