<template>
  <octo-table
    show-filters
    enable-export
    :action="endpoints.DATATABLES.users"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
  >
    <template v-slot:username="data">
      {{ data.row | optional('lastname') }}
      {{ data.row | optional('firstname') }}
      <div class="small">{{ data.row.username }}</div>
    </template>

    <template v-slot:active="data">
      <octo-icon :icon="data.row.active ? 'checked' : 'wrong'"/>
    </template>

    <template v-slot:temp_password="data">
      <octo-icon :icon="data.row.temp_password ? 'checked' : 'wrong'"/>
    </template>

    <template v-slot:roles="data">
      <badge v-for="(role, index) in data.row.roles"
             v-bind:key="index"
             :type="role.name | roleColor"
             class="text-uppercase small mr-2">
        {{ $t('auth.roles.' + role.name) }}
      </badge>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end" v-if="permissionService.isAllowed([permissionService.ADMIN_ROLE])">
        <base-button
          icon
          link
          class="mx-1"
          type="primary"
          size="sm"
          v-if="data.row.defaultEmail"
          @click="generateNewPassword(data.row)"
        >
          <octo-icon icon="key"/>
        </base-button>

        <base-button
          link
          icon
          class="mx-1"
          size="sm"
          @click="$router.push({name: 'users.show', params: {id : data.row.id }})"
        >
          <octo-icon icon="right-arrow"/>
        </base-button>
      </div>
    </template>

    <template slot="custom-filters">
      <div class="col-12 col-md-5">
        <base-input :label="$t('datatable.roles')">
          <el-select
            multiple
            filterable
            remote
            reserve-keyword
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.roles"
          >
            <el-option
              v-for="option in rolesOptions"
              class="select-default text-uppercase"
              :value="option"
              :label="$t('auth.roles.' + option)"
              :key="option"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-md-2">
        <base-input :label="$t('datatable.active')">
          <el-select
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="filters.active"
          >
            <el-option class="select-default text-uppercase"
                       :value="false"
                       :label="$t('common.no')"
                       key="active-false"
            ></el-option>
            <el-option class="select-default text-uppercase"
                       :value="true"
                       :label="$t('common.yes')"
                       key="active-true"
            ></el-option>
          </el-select>
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import Badge from "@/components/Badge";
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import {Select, Option} from "element-ui";
import {mapGetters} from "vuex";
import {permissionService} from "@/util/permission-service";

export default {
  name: "UserDatatable",
  components: {Badge, OctoIcon, OctoTable, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      name: 'UserDatatable',
      endpoints: endpoints,
      permissionService: permissionService,
      filters: {},
      fields: [
        {prop: 'username', label: 'username', slot: true},
        {prop: 'temp_password', label: 'temp_psw', width: 100, slot: true, align: 'center'},
        {prop: 'active', label: 'active', width: 100, slot: true, align: 'center'},
        {prop: 'roles', label: 'roles', slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', width: 150, slot: true},
      ],
      rolesOptions: [
        'admin',
        'call-center-manager',
        'call-center-operator',
        'sales-manager',
        'sales',
        'tutor',
        'teaching-manager',
        'accountant'
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
    }),
  },
  methods: {
    generateNewPassword(user) {
      this.$api.put(endpoints.USER_GENERATE_NEW_PASSWORD.replace('{id}', user.id))
        .then(() => {
          this.$notify({type: 'success', message: this.$t('notifications.password_updated')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.password_not_updated')});
        });
    },
  }
}
</script>

<style scoped>

</style>
