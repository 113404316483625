<template>
  <div>ciccio</div>
</template>

<script>
export default {
  name: "UserIndexHelper"
}
</script>

<style scoped>

</style>
